<template>
  <div class="speedometer__container">
    <div  class="speedometer__container--title">
      <h2>Nombre Metrica</h2>
    </div>
    <div  class="speedometer__container--gauge">
       <VueSvgGauge
      :start-angle="-115"
      :end-angle="115"
      :value="value"
      :separator-step="1"
      :min="0"
      :max="quantity"
      :gauge-color="[{ offset: 0, color: 'red'},{ offset: 50, color: 'yellow'},{ offset: 100, color: 'green'}]"
      :scale-interval="0.8"
    />
      <div  class="speedometer__container--gauge__details">
        <div class="speedometer__container--details_high">
          <p>Alto</p><div class="square green"></div>
        </div>
        <div class="speedometer__container--details_high">
          <p>Medio</p><div class="square yellow"></div>
        </div>
        <div class="speedometer__container--details_high">
          <p>Bajo</p><div class="square red"></div>
        </div>
        <div class="speedometer__container--details_high">
          <p>Porcentaje</p><p>{{result}}%</p>
        </div>
    </div>
    </div>
    

      <!--  or  -->
      <!-- <vue-svg-gauge
        :start-angle="-110"
        :end-angle="110"
        :value="5"
        :separator-step="0"
        :min="0"
        :max="10"
        gauge-color="#8CDFAD"
        :scale-interval="0.1"
      />
      <VueSvgGauge
        :start-angle="-110"
        :end-angle="110"
        :value="random"
        :separator-step="20"
        :scale-interval="10"
        :inner-radius="80"
      /> -->
  </div>
</template>
<script>
export default {
  data(){
    return{
      value:8.5,
      result:'',
      quantity:10
    }
    
  },
  methods:{
    calculatePercent(){
      this.result=Math.trunc((this.value/this.quantity)*100)
    }
  },
  beforeMount(){
    this.calculatePercent()
  }
}
</script>

<style scoped>
.speedometer__container{
  width: 60%;
  margin: 0 auto;
  display:flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border: 1px solid rgb(2, 1, 1);
  padding: 30px;
}
.speedometer__container--title{
  width: 100%;
}
.speedometer__container--title h2{
  text-align: left;
}
.speedometer__container--gauge{
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 1fr;
}
.speedometer__container--details_high{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.square{
  width: 60px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid black;
}
.green{
  background-color: rgba(0, 128, 21,0.6);
}
.red{
  background-color: rgba(255, 0, 0,0.6);
}
.yellow{
  background-color: rgba(255, 255, 0,0.6);
}
</style>