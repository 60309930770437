import { render, staticRenderFns } from "./prueba.vue?vue&type=template&id=a92841ec&scoped=true&"
import script from "./prueba.vue?vue&type=script&lang=js&"
export * from "./prueba.vue?vue&type=script&lang=js&"
import style0 from "./prueba.vue?vue&type=style&index=0&id=a92841ec&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a92841ec",
  null
  
)

export default component.exports